$(document).ready(function() {
    setTimeout(function(){
        $('.notification').fadeOut()
    }, 5000);

    var hamburger = $('.hamburger-icon');
    hamburger.click(function() {
        hamburger.toggleClass('active');
        hamburger.next().toggleClass('opened');
        return false;
    });

    $('.hover').bind('touchstart touchend', function(e) {
        $(this).toggleClass('touch_hover ');;
    });

    $('.fancy').fancybox();


    $('.services-nav ul span').click(function(){
        $(this).toggleClass('expanded');
        $(this).next().slideToggle();;
    })


    $('.slider').slick({
        autoplay: true,
        arrows: false,
        dots: false,
        fade: false,
        speed: 500,
        autoplaySpeed: 3000
    });

    $('.cases-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    })
});